<template>
  <div>
    <v-card flat elevation="0" class="">
      <app-data-table ref="refAppDataTable" :uri="datatable.uri" :headers="datatable.headers">
        <template #toolbar>
          <span>Employee Data</span>
        </template>
        <template v-slot:id="{ item }" v-if="!isMobile">
          <div class="d-inline-block">
            <app-tooltip text="Edit">
              <v-btn small color="primary" @click="redirect('HumanResource.Employee.Edit', { id: item.id })" icon><v-icon>mdi-pencil</v-icon></v-btn>
            </app-tooltip>
          </div>
        </template>
        <template v-slot:emp_id="{ item }" v-if="!isMobile">
          <a href="javascript:;" @click="showDetail(item)">{{ item.emp_id }}</a>
        </template>
        <template v-slot:status="{ item }" v-if="!isMobile">
          <span v-if="item.status == true" class="mx-auto">
            <app-tooltip text="Active">
              <v-icon dense color="success">mdi-checkbox-marked-circle-outline</v-icon>
            </app-tooltip>
          </span>
          <span v-else class="mx-auto">
            <app-tooltip text="In Active">
              <v-icon dense color="error">mdi-block-helper</v-icon>
            </app-tooltip>
          </span>
        </template>
      </app-data-table>
    </v-card>

    <v-navigation-drawer v-model="drawer" fixed bottom temporary right width="360" class="pt-12">
      <v-toolbar color="primary" dark elevation="0" dense absolute width="100%">
        <v-toolbar-title>{{ selectedEmployee.full_name }}</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn icon text small @click.stop="drawer = false">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-toolbar>
      <v-divider></v-divider>
      <detail :employee="selectedEmployee"></detail>
    </v-navigation-drawer>
  </div>
</template>

<script>
import Detail from './Detail.vue';
export default {
  components: { Detail },
  data() {
    return {
      loading: null,

      datatable: {
        uri: "employee/data",
        headers: [
          { text: "#", value: "id", class: "text-no-wrap" },
          { text: "Emp ID", value: "emp_id", class: "text-no-wrap" },
          { text: "Full Name", value: "full_name", class: "text-no-wrap" },
          { text: "Emp No.", value: "emp_no_", class: "text-no-wrap" },
          // { text: "Email", value: "email", class: "text-no-wrap" },
          { text: "Pos Name (en)", value: "pos_name_eng", class: "text-no-wrap" },
          { text: "Work Loc.", value: "work_location_code", class: "text-no-wrap" },
          { text: "Spv Parent", value: "spv_parent", class: "text-no-wrap" },
          { text: "Spv Name", value: "spv_name", class: "text-no-wrap" },
          // { text: "Spv Path", value: "spv_path", class: "text-no-wrap" },
          { text: "Status", value: "status", class: "text-no-wrap", cellClass: "text-center" },
        ],
      },

      selectedEmployee: {},
      drawer: null,
    };
  },
  methods: {
    refreshData() {
      this.$refs.refAppDataTable.refresh();
    },
    showDetail(item) {
      this.selectedEmployee = item;
      this.drawer = true;
    }
  },
};
</script>
